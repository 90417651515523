import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Chip,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  IconButton,
  RadioGroup,
  Radio,
} from '@mui/material';
import config from './config';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Loader from './Components/Loader';

const AddJobPage = () => {
  const [title, setTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [description, setDescription] = useState('');
  const [companyValues, setCompanyValues] = useState('');
  const [companyBackground, setCompanyBackground] = useState('');
  const [idealSkills, setIdealSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState('');
  const [questions, setQuestions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    question: '',
    questionType: 'shortAnswer',
    evaluationCriteria: '',
    criteriaScoreFive: '',
    criteriaScoreOne: '',
    criteriaYes: '',
    criteriaNo: '',
    parsingInstructions: '',
  });
  const [conversationIntro, setConversationIntro] = useState('');
  const [conversationOutro, setConversationOutro] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [testJob, setTestJob] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [agentType, setAgentType] = useState('behavioral');
  const [applicationForm, setApplicationForm] = useState({
    location: { name: "Location", requirement: "hidden" },
    linkedin: { name: "LinkedIn Profile", requirement: "hidden" },
    twitter: { name: "Twitter Profile", requirement: "hidden" },
    github: { name: "GitHub Profile", requirement: "hidden" },
    dribbble: { name: "Dribbble Profile", requirement: "hidden" },
    website: { name: "Website URL", requirement: "hidden" },
    resume: { name: "Resume", requirement: "required" },
  });
  const [customQuestions, setCustomQuestions] = useState([]);
  const [newCustomQuestion, setNewCustomQuestion] = useState({
    question: '',
    questionType: 'shortAnswer',
    requirement: 'optional',
    choices: [''],
  });
  const [editingCustomQuestionIndex, setEditingCustomQuestionIndex] = useState(-1);
  const [openCustomQuestionDialog, setOpenCustomQuestionDialog] = useState(false);
  const [automaticReminders, setAutomaticReminders] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (location.state && location.state.editJob) {
        try {
          const response = await fetch(`${config.API_URL}/jobs/${location.state.editJob.id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          if (!response.ok) {
            throw new Error('Failed to fetch job details');
          }
          const jobData = await response.json();
          // Populate form fields with jobData
          setTitle(jobData.title);
          setCompanyName(jobData.company_name);
          setDescription(jobData.description);
          setCompanyValues(jobData.company_values || '');
          setCompanyBackground(jobData.company_background || '');
          setIdealSkills(jobData.ideal_skills || []);
          setQuestions(jobData.interview_questions.map(q => ({
            question: q.question,
            questionType: q.question_type,
            evaluationCriteria: q.evaluation_criteria || '',
            criteriaScoreFive: q.criteria_score_five || '',
            criteriaScoreOne: q.criteria_score_one || '',
            criteriaYes: q.criteria_yes || '',
            criteriaNo: q.criteria_no || '',
            parsingInstructions: q.parsing_instructions || '',
          })));
          setConversationIntro(jobData.conversation_intro || '');
          setConversationOutro(jobData.conversation_outro || '');
          setSelectedOrganization(jobData.organization_id);
          setTestJob(jobData.test_job);
          setAgentType(jobData.agent_type || 'behavioral');
          setApplicationForm(jobData.application_form);
          setCustomQuestions(jobData.application_form.custom_questions.map(q => ({
            question: q.question,
            questionType: q.question_type,
            requirement: q.requirement,
            choices: q.choices || [],
          })));
          setAutomaticReminders(jobData.reminders_enabled);
        } catch (error) {
          console.error('Error fetching job details:', error);
          setError('Failed to load job details. Please try again.');
        }
      }
    };

    fetchJobDetails();
  }, [location.state]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await fetch(`${config.API_URL}/organizations/`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch organizations');
        }
        const data = await response.json();
        setOrganizations(data);
        if (data.length > 0) {
          setSelectedOrganization(data[0].id);
        }
      } catch (err) {
        setError('Failed to load organizations. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();

    // Check if we're editing an existing job
    if (location.state && location.state.editJob) {
      const job = location.state.editJob;
      setTitle(job.title);
      setCompanyName(job.company_name || '');
      setDescription(job.description);
      setCompanyValues(job.company_values || '');
      setCompanyBackground(job.company_background || '');
      setIdealSkills(job.ideal_skills || []);
      setQuestions(job.questions || []);
      setConversationIntro(job.conversation_intro || '');
      setConversationOutro(job.conversation_outro || '');
      setSelectedOrganization(job.organization_id);
      setTestJob(job.test_job || false);
      setAgentType(job.agent_type || 'behavioral');
      setApplicationForm(job.application_form || {
        location: { name: "Location", requirement: "hidden" },
        linkedin: { name: "LinkedIn Profile", requirement: "hidden" },
        twitter: { name: "Twitter Profile", requirement: "hidden" },
        github: { name: "GitHub Profile", requirement: "hidden" },
        dribbble: { name: "Dribbble Profile", requirement: "hidden" },
        website: { name: "Website URL", requirement: "hidden" },
        resume: { name: "Resume", requirement: "required" },
      });
      setCustomQuestions(job.custom_questions || []);
      setAutomaticReminders(job.reminders_enabled || true);
    }
  }, [location.state]);

  const handleSkillInputChange = (event) => {
    setCurrentSkill(event.target.value);
  };

  const handleSkillInputKeyDown = (event) => {
    if (event.key === 'Enter' && currentSkill.trim()) {
      event.preventDefault();
      setIdealSkills([...idealSkills, currentSkill.trim()]);
      setCurrentSkill('');
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    setIdealSkills(idealSkills.filter((skill) => skill !== skillToDelete));
  };

  const handleOpenDialog = (index = -1) => {
    if (index >= 0) {
      setNewQuestion(questions[index]);
      setEditingIndex(index);
    } else {
      setNewQuestion({
        question: '',
        questionType: 'shortAnswer',
        evaluationCriteria: '',
        criteriaScoreFive: '',
        criteriaScoreOne: '',
        criteriaYes: '',
        criteriaNo: '',
        parsingInstructions: '',
      });
      setEditingIndex(-1);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingIndex(-1);
    setNewQuestion({
      question: '',
      questionType: 'shortAnswer',
      evaluationCriteria: '',
      criteriaScoreFive: '',
      criteriaScoreOne: '',
      criteriaYes: '',
      criteriaNo: '',
      parsingInstructions: '',
    });
  };

  const handleQuestionChange = (event) => {
    const { name, value } = event.target;
    setNewQuestion(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveQuestion = () => {
    if (newQuestion.question && newQuestion.questionType) {
      let isValid = true;
      switch (newQuestion.questionType) {
        case 'shortAnswer':
          isValid = newQuestion.evaluationCriteria && newQuestion.criteriaScoreFive && newQuestion.criteriaScoreOne;
          break;
        case 'boolean':
          isValid = newQuestion.criteriaYes && newQuestion.criteriaNo;
          break;
        case 'number':
          isValid = newQuestion.parsingInstructions;
          break;
        default:
          isValid = false;
          console.warn(`Unexpected question type: ${newQuestion.questionType}`);
          break;
      }

      if (isValid) {
        if (editingIndex >= 0) {
          const updatedQuestions = [...questions];
          updatedQuestions[editingIndex] = newQuestion;
          setQuestions(updatedQuestions);
        } else {
          setQuestions([...questions, newQuestion]);
        }
        handleCloseDialog();
      }
    }
  };

  const handleDeleteQuestion = (indexToDelete) => {
    setQuestions(questions.filter((_, index) => index !== indexToDelete));
  };

  const handleApplicationFormChange = (field) => (event) => {
    setApplicationForm(prev => ({
      ...prev,
      [field]: { ...prev[field], requirement: event.target.value }
    }));
  };

  const handleOpenCustomQuestionDialog = (index = -1) => {
    if (index >= 0) {
      const question = customQuestions[index];
      setNewCustomQuestion({
        ...question,
        questionType: question.questionType || question.question_type,
      });
      setEditingCustomQuestionIndex(index);
    } else {
      setNewCustomQuestion({
        question: '',
        questionType: 'shortAnswer',
        requirement: 'optional',
        choices: [''],
      });
      setEditingCustomQuestionIndex(-1);
    }
    setOpenCustomQuestionDialog(true);
  };

  const handleCloseCustomQuestionDialog = () => {
    setOpenCustomQuestionDialog(false);
    setEditingCustomQuestionIndex(-1);
    setNewCustomQuestion({
      question: '',
      questionType: 'shortAnswer',
      requirement: 'optional',
      choices: [''],
    });
  };

  const handleSaveCustomQuestion = () => {
    if (newCustomQuestion.question && newCustomQuestion.questionType) {
      let updatedQuestion = {
        ...newCustomQuestion,
        question_type: newCustomQuestion.questionType,
      };

      // Only filter choices if they exist and the question type requires choices
      if (['singleSelect', 'multiSelect'].includes(updatedQuestion.questionType) && Array.isArray(updatedQuestion.choices)) {
        updatedQuestion.choices = updatedQuestion.choices.filter(choice => choice.trim() !== '');
      } else {
        // For other question types, ensure choices is undefined
        delete updatedQuestion.choices;
      }

      if (editingCustomQuestionIndex >= 0) {
        const updatedQuestions = [...customQuestions];
        updatedQuestions[editingCustomQuestionIndex] = updatedQuestion;
        setCustomQuestions(updatedQuestions);
      } else {
        setCustomQuestions(prevQuestions => [...prevQuestions, updatedQuestion]);
      }
      handleCloseCustomQuestionDialog();
    }
  };

  const handleDeleteCustomQuestion = (indexToDelete) => {
    setCustomQuestions(customQuestions.filter((_, index) => index !== indexToDelete));
  };



  const handleCustomQuestionChange = (event) => {
    const { name, value } = event.target;
    setNewCustomQuestion(prev => {
      if (name === 'questionType') {
        return {
          ...prev,
          [name]: value,
          choices: (value === 'singleSelect' || value === 'multiSelect') ? [''] : [],
        };
      }
      return { ...prev, [name]: value };
    });
  };

  const handleChoiceChange = (index, value) => {
    setNewCustomQuestion(prev => {
      const newChoices = [...prev.choices];
      newChoices[index] = value;
      return { ...prev, choices: newChoices };
    });
  };

  const addChoice = () => {
    setNewCustomQuestion(prev => ({
      ...prev,
      choices: [...prev.choices, ''],
    }));
  };

  const removeChoice = (index) => {
    setNewCustomQuestion(prev => ({
      ...prev,
      choices: prev.choices.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Custom Questions before submission:", customQuestions);
    const jobData = {
      title,
      description,
      company_values: companyValues,
      company_background: companyBackground,
      ideal_skills: idealSkills,
      questions: questions.map(q => q.question),
      conversation_intro: conversationIntro,
      conversation_outro: conversationOutro,
      organization_id: selectedOrganization,
      company_name: companyName,
      test_job: testJob,
      agent_type: agentType,
      application_form: {
        ...Object.fromEntries(
          Object.entries(applicationForm).map(([key, value]) => [key, value])
        ),
        custom_questions: customQuestions.map(q => ({
          question: q.question,
          question_type: q.questionType || q.question_type,
          requirement: q.requirement,
          choices: q.choices && q.choices.length > 0 ? q.choices : undefined
        }))
      },
      interview_questions: questions.map(q => ({
        question: q.question,
        question_type: q.questionType,
        evaluation_criteria: q.evaluationCriteria,
        criteria_score_five: q.criteriaScoreFive,
        criteria_score_one: q.criteriaScoreOne,
        criteria_yes: q.criteriaYes,
        criteria_no: q.criteriaNo,
        parsing_instructions: q.parsingInstructions,
      })),
      reminders_enabled: automaticReminders,
    };
    console.log("Job data being submitted:", jobData);

    try {
      const url = location.state && location.state.editJob
        ? `${config.API_URL}/jobs/${location.state.editJob.id}`
        : `${config.API_URL}/jobs/`;

      const method = location.state && location.state.editJob ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(jobData)
      });

      if (response.ok) {
        navigate('/dashboard');
      } else {
        const errorData = await response.json();
        console.error('Failed to add/edit job:', errorData.detail || errorData);
        setError('Failed to save job. Please try again.');
      }
    } catch (error) {
      console.error('Error adding/editing job:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const renderFormField = (label, field, alwaysRequired = false) => (
    <Grid item xs={12} key={field}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>{label}</Typography>
      <ToggleButtonGroup
        value={alwaysRequired ? 'required' : applicationForm[field].requirement}
        exclusive
        onChange={(e, newValue) => {
          if (newValue !== null) {
            handleApplicationFormChange(field)({ target: { value: newValue } });
          }
        }}
        aria-label={`${label} requirement`}
        fullWidth
        disabled={alwaysRequired}
      >
        <ToggleButton
          value="required"
          aria-label="required"
          sx={{
            '&.Mui-selected': {
              bgcolor: '#4caf50 !important',
              color: 'white !important'
            }
          }}
        >
          Required
        </ToggleButton>
        <ToggleButton
          value="optional"
          aria-label="optional"
          sx={{
            '&.Mui-selected': {
              bgcolor: '#ff9800 !important',
              color: 'white !important'
            }
          }}
        >
          Optional
        </ToggleButton>
        <ToggleButton
          value="hidden"
          aria-label="hidden"
          sx={{
            '&.Mui-selected': {
              bgcolor: '#f44336 !important',
              color: 'white !important'
            }
          }}
        >
          Hidden
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );

  const renderFormField2 = (label, value, onChange, multiline = false, rows = 1, required = false, description = '') => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
        {label}{required && <span style={{ color: 'red' }}> *</span>}
      </Typography>
      {description && (
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          {description}
        </Typography>
      )}
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        required={required}
        variant="outlined"
        multiline={multiline}
        rows={rows}
      />
    </Box>
  );

  const moveQuestionUp = (index) => {
    if (index > 0) {
      const newQuestions = [...questions];
      [newQuestions[index - 1], newQuestions[index]] = [newQuestions[index], newQuestions[index - 1]];
      setQuestions(newQuestions);
    }
  };

  const moveQuestionDown = (index) => {
    if (index < questions.length - 1) {
      const newQuestions = [...questions];
      [newQuestions[index], newQuestions[index + 1]] = [newQuestions[index + 1], newQuestions[index]];
      setQuestions(newQuestions);
    }
  };

  // if (loading) {
  //   return (
  //     <Container maxWidth="md">
  //       <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
  //         <CircularProgress />
  //       </Box>
  //     </Container>
  //   );
  // }
  if (loading) {
    return (
      <Loader />
    );
  }
  if (error) {
    return (
      <Container maxWidth="md">
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
        <Typography variant="h4" sx={{ mb: 4, color: '#4a8f99' }}>
          {location.state && location.state.editJob ? 'Edit Job Posting' : 'Add New Job Posting'}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="organization-select-label">Organization</InputLabel>
            <Select
              labelId="organization-select-label"
              value={selectedOrganization}
              onChange={(e) => setSelectedOrganization(e.target.value)}
              label="Organization"
              required
            >
              {organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="h5" sx={{ mb: 3, mt: 4, borderBottom: '2px solid #4a8f99', pb: 1 }}>
            Screening Setup
          </Typography>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Screening Style
            </Typography>
            <RadioGroup
              value={agentType}
              onChange={(e) => setAgentType(e.target.value)}
            >
              <FormControlLabel
                value="behavioral"
                control={<Radio />}
                label={
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Behavioral</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Evaluates candidate fit based on general criteria, focusing on qualities aligned with company culture and values.
                    </Typography>
                  </Box>
                }
                sx={{ mb: 2 }}
              />
              <FormControlLabel
                value="role-based"
                control={<Radio />}
                label={
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1">Role-based</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Uses a structured flow of questions to assess fit, with additional prompts as needed to maintain a 10-minute screening duration.
                    </Typography>
                  </Box>
                }
                sx={{ mb: 2 }}
              />
              <FormControlLabel
                value="key-qualifier"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box>
                      <Typography variant="subtitle1">
                        Key Qualifier
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Uses a structured flow of questions to assess fit, with additional prompts as needed to maintain a 10-minute screening duration.
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Screening Questions
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Add standardized questions for Pluto to ask every candidate during the screening process.
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenDialog()}
              sx={{
                mb: 2,
                bgcolor: '#4a8f99',
                '&:hover': { bgcolor: '#3b7b84' }
              }}
            >
              Add Screening Question
            </Button>
            {questions.map((q, index) => (
              <Paper key={index} elevation={2} sx={{ p: 2, mb: 2, borderLeft: '4px solid #4a8f99' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                    #{index + 1}: {q.question}
                  </Typography>
                  <IconButton onClick={() => moveQuestionUp(index)} disabled={index === 0}>
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton onClick={() => moveQuestionDown(index)} disabled={index === questions.length - 1}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                  {q.questionType === 'shortAnswer' && (
                    <Chip label="Short Answer" color="primary" variant="outlined" />
                  )}
                  {q.questionType === 'boolean' && (
                    <Chip label="Yes/No" color="secondary" variant="outlined" />
                  )}
                  {q.questionType === 'number' && (
                    <Chip label="Numeric" color="info" variant="outlined" />
                  )}
                </Box>
                {q.questionType === 'shortAnswer' && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Evaluation Criteria:</Typography>
                    <Typography variant="body2">{q.evaluationCriteria}</Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>Score 5 Criteria:</Typography>
                    <Typography variant="body2">{q.criteriaScoreFive}</Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>Score 1 Criteria:</Typography>
                    <Typography variant="body2">{q.criteriaScoreOne}</Typography>
                  </Box>
                )}
                {q.questionType === 'boolean' && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Yes Criteria:</Typography>
                    <Typography variant="body2">{q.criteriaYes}</Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>No Criteria:</Typography>
                    <Typography variant="body2">{q.criteriaNo}</Typography>
                  </Box>
                )}
                {q.questionType === 'number' && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Parsing Instructions:</Typography>
                    <Typography variant="body2">{q.parsingInstructions}</Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => handleOpenDialog(index)}
                    variant="outlined"
                  >
                    Edit
                  </Button>
                  <Button
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteQuestion(index)}
                    variant="outlined"
                    color="error"
                  >
                    Delete
                  </Button>
                </Box>
              </Paper>
            ))}
          </Box>

          {renderFormField2(
            "Conversation Intro",
            conversationIntro,
            setConversationIntro,
            true,
            2,
            false,
            'Enter a personalized introduction to start the conversation with the candidate. Note: End the intro with a simple question, like "Ready to begin?" so the candidate knows when to respond.'
          )}
          {renderFormField2(
            "Conversation Outro",
            conversationOutro,
            setConversationOutro,
            true,
            2,
            false,
            'Provide a closing message to end the screening conversation.'
          )}

          <Typography variant="h5" sx={{ mb: 3, mt: 4, borderBottom: '2px solid #4a8f99', pb: 1 }}>
            Details
          </Typography>

          {renderFormField2("Company Name", companyName, setCompanyName, false, 1, true)}
          {renderFormField2("Job Title", title, setTitle, false, 1, true)}
          {renderFormField2(
            agentType === "behavioral" ? "Evaluation Criteria" : "Job Description",
            description,
            setDescription,
            true,
            4,
            true
          )}
          {renderFormField2("Company Values", companyValues, setCompanyValues, true, 4)}
          {renderFormField2("Company Background", companyBackground, setCompanyBackground, true, 4)}

          <Box sx={{ mb: 3 }}>
            <TextField
              label="Add Ideal Skills"
              value={currentSkill}
              onChange={handleSkillInputChange}
              onKeyDown={handleSkillInputKeyDown}
              fullWidth
              placeholder="Type a skill and press Enter"
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
              {idealSkills.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  onDelete={() => handleDeleteSkill(skill)}
                  color="primary"
                />
              ))}
            </Box>
          </Box>

          <Typography variant="h5" sx={{ mb: 3, mt: 4, borderBottom: '2px solid #4a8f99', pb: 1 }}>
            Settings
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Test Job Mode
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Enable unlimited test applications for internal evaluation purposes.
            </Typography>
            <ToggleButtonGroup
              value={testJob ? 'test' : 'production'}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setTestJob(newValue === 'test');
                }
              }}
              aria-label="test job mode"
              fullWidth
            >
              <ToggleButton value="production" aria-label="production mode">
                Production
              </ToggleButton>
              <ToggleButton value="test" aria-label="test mode">
                Test Mode
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Automatic Reminders
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Send three reminder emails over a 72-hour period for applications pending action.
              Note: We recommend disabling this feature in test mode, as it may cause multiple reminders for users with several applications.
            </Typography>
            <ToggleButtonGroup
              value={automaticReminders ? 'enabled' : 'disabled'}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setAutomaticReminders(newValue === 'enabled');
                }
              }}
              aria-label="automatic reminders"
              fullWidth
            >
              <ToggleButton value="enabled" aria-label="enabled">
                Enabled
              </ToggleButton>
              <ToggleButton value="disabled" aria-label="disabled">
                Disabled
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Typography variant="h5" sx={{ mb: 3, mt: 4, borderBottom: '2px solid #4a8f99', pb: 1 }}>
            Application Form
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Select the information fields candidates can provide when applying in a traditional form:
          </Typography>

          <Grid container spacing={2}>
            {renderFormField('Location', 'location')}
            {renderFormField('LinkedIn Profile', 'linkedin')}
            {renderFormField('Twitter Profile', 'twitter')}
            {renderFormField('GitHub Profile', 'github')}
            {renderFormField('Dribbble Profile', 'dribbble')}
            {renderFormField('Website URL', 'website')}
            {renderFormField('Resume', 'resume')}
          </Grid>

          <Box sx={{ mt: 4, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Application Questions
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Add additional questions to the application form for candidates to answer when applying.
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleOpenCustomQuestionDialog()}
              sx={{
                mb: 2,
                bgcolor: '#4a8f99',
                '&:hover': { bgcolor: '#3b7b84' }
              }}
            >
              Add Application Question
            </Button>
          </Box>

          {customQuestions.map((q, index) => {
            console.log(`Question ${index}:`, q);
            return (
              <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
                <Typography variant="body1">{q.question}</Typography>
                <Typography variant="body2">Type: {q.questionType || q.question_type}</Typography>
                <Typography variant="body2">Requirement: {q.requirement}</Typography>
                {(q.questionType === 'singleSelect' || q.questionType === 'multiSelect') && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2">Choices:</Typography>
                    <ul>
                      {q.choices && q.choices.map((choice, i) => (
                        <li key={i}>{choice}</li>
                      ))}
                    </ul>
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => handleOpenCustomQuestionDialog(index)}
                  >
                    Edit
                  </Button>
                  <Button
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteCustomQuestion(index)}
                  >
                    Delete
                  </Button>
                </Box>
              </Paper>
            );
          })}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              bgcolor: '#4a8f99',
              '&:hover': { bgcolor: '#3b7b84' }
            }}
          >
            {location.state && location.state.editJob ? 'Update Job Posting' : 'Add Job Posting'}
          </Button>
        </Box>
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editingIndex >= 0 ? 'Edit' : 'Add'} Question</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="question"
            label="Question"
            type="text"
            fullWidth
            variant="outlined"
            value={newQuestion.question}
            onChange={handleQuestionChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Question Type</InputLabel>
            <Select
              name="questionType"
              value={newQuestion.questionType}
              onChange={handleQuestionChange}
            >
              <MenuItem value="shortAnswer">Short Answer</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
              <MenuItem value="number">Number</MenuItem>
            </Select>
          </FormControl>
          {newQuestion.questionType === 'shortAnswer' && (
            <>
              <TextField
                margin="dense"
                name="evaluationCriteria"
                label="Evaluation Criteria"
                type="text"
                fullWidth
                variant="outlined"
                value={newQuestion.evaluationCriteria}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="criteriaScoreFive"
                label="Criteria of Score 5"
                type="text"
                fullWidth
                variant="outlined"
                value={newQuestion.criteriaScoreFive}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="criteriaScoreOne"
                label="Criteria of Score 1"
                type="text"
                fullWidth
                variant="outlined"
                value={newQuestion.criteriaScoreOne}
                onChange={handleQuestionChange}
              />
            </>
          )}
          {newQuestion.questionType === 'boolean' && (
            <>
              <TextField
                margin="dense"
                name="criteriaYes"
                label="Criteria for Yes"
                type="text"
                fullWidth
                variant="outlined"
                value={newQuestion.criteriaYes}
                onChange={handleQuestionChange}
              />
              <TextField
                margin="dense"
                name="criteriaNo"
                label="Criteria for No"
                type="text"
                fullWidth
                variant="outlined"
                value={newQuestion.criteriaNo}
                onChange={handleQuestionChange}
              />
            </>
          )}
          {newQuestion.questionType === 'number' && (
            <TextField
              margin="dense"
              name="parsingInstructions"
              label="Parsing Instructions"
              type="text"
              fullWidth
              variant="outlined"
              value={newQuestion.parsingInstructions}
              onChange={handleQuestionChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveQuestion}>{editingIndex >= 0 ? 'Save' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCustomQuestionDialog} onClose={handleCloseCustomQuestionDialog}>
        <DialogTitle>{editingCustomQuestionIndex >= 0 ? 'Edit' : 'Add'} Custom Question</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="question"
            label="Question"
            type="text"
            fullWidth
            variant="outlined"
            value={newCustomQuestion.question}
            onChange={handleCustomQuestionChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Question Type</InputLabel>
            <Select
              name="questionType"
              value={newCustomQuestion.questionType}
              onChange={handleCustomQuestionChange}
            >
              <MenuItem value="shortAnswer">Short Answer</MenuItem>
              <MenuItem value="longAnswer">Long Answer</MenuItem>
              <MenuItem value="singleSelect">Multiple-choice (single select)</MenuItem>
              <MenuItem value="multiSelect">Multiple-choice (multi select)</MenuItem>
              <MenuItem value="fileUpload">File Upload</MenuItem>
              <MenuItem value="number">Number</MenuItem>
            </Select>
          </FormControl>
          {(newCustomQuestion.questionType === 'singleSelect' || newCustomQuestion.questionType === 'multiSelect') && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>Answer Choices</Typography>
              {newCustomQuestion.choices.map((choice, index) => (
                <Box key={index} sx={{ display: 'flex', mb: 1 }}>
                  <TextField
                    value={choice}
                    onChange={(e) => handleChoiceChange(index, e.target.value)}
                    fullWidth
                    size="small"
                    placeholder={`Choice ${index + 1}`}
                  />
                  <Button onClick={() => removeChoice(index)} sx={{ ml: 1 }}>
                    Remove
                  </Button>
                </Box>
              ))}
              <Button onClick={addChoice} variant="outlined" size="small" sx={{ mt: 1 }}>
                Add Choice
              </Button>
            </Box>
          )}
          <FormControl fullWidth margin="dense">
            <InputLabel>Question Requirement</InputLabel>
            <Select
              name="requirement"
              value={newCustomQuestion.requirement}
              onChange={handleCustomQuestionChange}
            >
              <MenuItem value="required">Required</MenuItem>
              <MenuItem value="optional">Optional</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomQuestionDialog}>Cancel</Button>
          <Button onClick={handleSaveCustomQuestion}>{editingCustomQuestionIndex >= 0 ? 'Save' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AddJobPage;