import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Container,
    Stack,
    Tooltip
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import apiClient from './apiClient';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

const SharePage = () => {
    const [resources, setResources] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { encodedShare } = useParams();

    const fetchResources = useCallback(async () => {
        console.log('Share token:', encodedShare);

        if (!encodedShare) {
            setError('Invalid share token');
            setLoading(false);
            return;
        }

        try {
            console.log('Making API request to:', `/jobs/applications/resources/${encodedShare}`);
            const response = await apiClient.get(`/jobs/applications/resources/${encodedShare}`);
            console.log('API response:', response.data);
            setResources(response.data);
        } catch (err) {
            console.error('API error:', err);
            setError(err.response?.data?.detail || 'An error occurred');
        } finally {
            setLoading(false);
        }
    }, [encodedShare]);

    useEffect(() => {
        fetchResources();
    }, [fetchResources]);

    const handleDownloadProfile = () => {
        if (!resources?.candidate) return;

        const doc = new jsPDF();
        const candidate = resources.candidate;

        // Helper function to add text with word wrap and page breaks
        const addWrappedText = (text, x, y, maxWidth, lineHeight) => {
            const lines = doc.splitTextToSize(text, maxWidth);
            lines.forEach((line, index) => {
                if (y > 280) {
                    doc.addPage();
                    y = 20;
                }
                doc.text(line, x, y);
                y += lineHeight;
            });
            return y;
        };

        // Helper function to check and add a new page if needed
        const checkNewPage = (y) => {
            if (y > 280) {
                doc.addPage();
                return 20;
            }
            return y;
        };

        // Set font and add candidate name
        doc.setFont("helvetica", "bold");
        doc.setFontSize(20);
        doc.text(`${candidate.first_name} ${candidate.last_name}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Email: ${candidate.email}`, 20, 30);

        // Table of Contents
        doc.setFontSize(16);
        doc.text("Table of Contents", 20, 45);
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        let tocY = 55;
        const sections = [
            "Interview Responses",
            "Strengths and Weaknesses",
            "Cultural Fit",
            "Sentiment",
            "Delivery of Speech",
            "Candidate Questions",
            "Top Skills",
            "Cohort"
        ];
        sections.forEach((section, index) => {
            doc.setTextColor(0, 0, 255);  // Set text color to blue for links
            doc.textWithLink(`${index + 1}. ${section}`, 25, tocY, { pageNumber: index + 2 });  // Link to the corresponding page
            doc.setTextColor(0);  // Reset text color to black
            tocY += 10;
        });

        // Content pages
        sections.forEach((section, index) => {
            doc.addPage();
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.text(`${index + 1}. ${section}`, 20, 20);
            let yPos = 40;

            switch (index) {
                case 0:  // Interview Responses
                    if (candidate.interview_responses && Object.keys(candidate.interview_responses).length > 0) {
                        Object.entries(candidate.interview_responses).forEach(([question, responseData]) => {
                            doc.setFontSize(12);
                            doc.setFont("helvetica", "bold");
                            yPos = addWrappedText(question, 20, yPos, 170, 7);
                            yPos += 5;

                            doc.setFont("helvetica", "normal");
                            if (responseData.question_type === 'shortAnswer') {
                                yPos = addWrappedText(`Answer: ${responseData.response.eval_reasoning}`, 30, yPos, 160, 7);
                                yPos = addWrappedText(`Score: ${responseData.response.eval_score}/5`, 30, yPos, 160, 7);
                            } else if (responseData.question_type === 'boolean') {
                                yPos = addWrappedText(`Answer: ${responseData.response.answer ? 'Yes' : 'No'}`, 30, yPos, 160, 7);
                                yPos = addWrappedText(`Reasoning: ${responseData.response.reasoning}`, 30, yPos, 160, 7);
                            } else if (responseData.question_type === 'number') {
                                yPos = addWrappedText(`Answer: ${responseData.response.answer}`, 30, yPos, 160, 7);
                                yPos = addWrappedText(`Reasoning: ${responseData.response.reasoning}`, 30, yPos, 160, 7);
                            }

                            yPos += 10;
                            yPos = checkNewPage(yPos);
                        });
                    } else {
                        doc.setFontSize(12);
                        doc.text("No interview responses available", 20, yPos);
                    }
                    break;
                case 1:  // Strengths and Weaknesses
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    doc.text("Strengths:", 20, yPos);
                    yPos += 10;
                    candidate.strengths?.forEach(strength => {
                        yPos = addWrappedText(`• ${strength}`, 30, yPos, 160, 7);
                    });

                    yPos = checkNewPage(yPos + 10);
                    doc.text("Weaknesses:", 20, yPos);
                    yPos += 10;
                    candidate.weaknesses?.forEach(weakness => {
                        yPos = addWrappedText(`• ${weakness}`, 30, yPos, 160, 7);
                    });
                    break;
                case 2:  // Cultural Fit
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    yPos = addWrappedText(candidate.cultural_fit_reasoning, 20, yPos, 170, 7);
                    break;
                case 3:  // Sentiment
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    yPos = addWrappedText(candidate.sentiment, 20, yPos, 170, 7);
                    break;
                case 4:  // Delivery of Speech
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    yPos = addWrappedText(candidate.speech_skills, 20, yPos, 170, 7);
                    break;
                case 5:  // Candidate Questions
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    if (candidate.candidate_questions && candidate.candidate_questions.length > 0) {
                        candidate.candidate_questions.forEach((question, idx) => {
                            yPos = addWrappedText(`${idx + 1}. ${question}`, 20, yPos, 170, 7);
                            yPos += 5;
                            yPos = checkNewPage(yPos);
                        });
                    } else {
                        doc.text("Candidate asked no questions", 20, yPos);
                    }
                    break;
                case 6:  // Top Skills
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    if (candidate.top_skills && candidate.top_skills.length > 0) {
                        candidate.top_skills.forEach((skillObj, idx) => {
                            yPos = addWrappedText(`${idx + 1}. ${skillObj.skill}`, 20, yPos, 170, 7);
                            yPos = addWrappedText(`   Reasoning: ${skillObj.reasoning}`, 20, yPos, 170, 7);
                            yPos += 5;
                            yPos = checkNewPage(yPos);
                        });
                    } else {
                        doc.text("No skills available", 20, yPos);
                    }
                    break;
                case 7:  // Cohort
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    const cohort = candidate.ranking === 0 ? 'C' :
                        candidate.ranking === 1 ? 'B' :
                            candidate.ranking === 2 ? 'A' : 'N/A';
                    doc.text(`Cohort: ${cohort}`, 20, yPos);
                    break;
                default:
                    doc.setFontSize(12);
                    doc.setFont("helvetica", "normal");
                    doc.text(`No specific content for section: ${section}`, 20, yPos);
                    break;
            }
        });

        // Save the PDF
        doc.save(`${candidate.first_name}_${candidate.last_name}_profile.pdf`);
    };

    // Add new function to handle downloading all files
    const handleDownloadAll = async () => {
        // Download resume if available
        if (resources?.resume_url) {
            window.open(resources.resume_url, '_blank');
        }

        // Download audio if available
        if (resources?.audio_url) {
            window.open(resources.audio_url, '_blank');
        }

        // Generate and download profile if candidate data exists
        if (resources?.candidate) {
            handleDownloadProfile();
        }
    };

    if (loading) return <Box display="flex" justifyContent="center" mt={4}>Loading...</Box>;
    if (error) return <Box display="flex" justifyContent="center" mt={4} color="error.main">{error}</Box>;
    if (!resources) return <Box display="flex" justifyContent="center" mt={4}>No resources found</Box>;

    return (
        <Container maxWidth="md">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
                py={4}
            >
                <Typography variant="h4" component="h1" gutterBottom align="center" mb={6}>
                    Applicant Profile
                </Typography>

                <Box width="100%" mb={4}>
                    <hr />
                </Box>

                {resources?.candidate && (
                    <Box mb={4} textAlign="center">
                        <Typography variant="h5" gutterBottom>
                            {`${resources.candidate.first_name} ${resources.candidate.last_name}`}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {resources.job.title || 'Job Title Not Available'}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {resources.job.company_name || 'Company Not Available'}
                        </Typography>
                    </Box>
                )}

                <Stack spacing={3} direction="column" alignItems="center" width="100%">
                    <Stack direction="column" spacing={3} justifyContent="center">
                        <Tooltip
                            title={!resources?.resume_url ? "This application has no resume" : ""}
                            arrow
                        >
                            <span>
                                <Button
                                    variant="outlined"
                                    startIcon={<DescriptionIcon sx={{ fontSize: 28 }} />}
                                    disabled={!resources?.resume_url}
                                    onClick={() => resources?.resume_url && window.open(resources.resume_url, '_blank')}
                                    sx={{
                                        minWidth: 300,
                                        height: 56,
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    Download Resume
                                </Button>
                            </span>
                        </Tooltip>

                        <Tooltip
                            title={!resources?.audio_url ? "This application has no audio" : ""}
                            arrow
                        >
                            <span>
                                <Button
                                    variant="outlined"
                                    startIcon={<AudioFileIcon sx={{ fontSize: 28 }} />}
                                    disabled={!resources?.audio_url}
                                    onClick={() => resources?.audio_url && window.open(resources.audio_url, '_blank')}
                                    sx={{
                                        minWidth: 300,
                                        height: 56,
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    Download Audio
                                </Button>
                            </span>
                        </Tooltip>

                        <Button
                            variant="outlined"
                            startIcon={<DescriptionIcon sx={{ fontSize: 28 }} />}
                            onClick={handleDownloadProfile}
                            disabled={!resources?.candidate}
                            sx={{
                                minWidth: 300,
                                height: 56,
                                fontSize: '1.1rem'
                            }}
                        >
                            Download Profile
                        </Button>

                        <Button
                            variant="contained"
                            startIcon={<DescriptionIcon sx={{ fontSize: 28 }} />}
                            onClick={handleDownloadAll}
                            disabled={!resources?.resume_url && !resources?.audio_url && !resources?.candidate}
                            sx={{
                                minWidth: 300,
                                height: 56,
                                fontSize: '1.1rem'
                            }}
                        >
                            Download All
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
};

export default SharePage;
