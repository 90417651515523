import { createTheme } from '@mui/material/styles';

const glowingGreen = '#4a8f99';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: glowingGreen,
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#F5F5F0', // Cream white color
      paper: '#FFFFFF', // White color for containers
    },
    text: {
      primary: '#000000', // Black text
      secondary: 'rgba(0, 0, 0, 0.7)', // Slightly faded black for secondary text
    },
    glowingGreen: {
      main: glowingGreen,
      light: '#6abbc7', // Lighter shade of glowing green
      dark: '#3a717a', // Darker shade of glowing green
    },
  },
  typography: {
    fontFamily: [
      'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 400,
    },
    subtitle2: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 500,
      textTransform: 'none', // Prevents all-caps buttons
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F5F0', // Cream white for AppBar
          color: '#000000', // Black text for AppBar
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#F5F5F0', // Cream white for Drawer (sidebar)
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF', // White for Paper components
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Slightly rounded buttons
        },
        containedPrimary: {
          backgroundColor: glowingGreen,
          '&:hover': {
            backgroundColor: '#3a717a', // Darker shade on hover
          },
        },
        outlinedPrimary: {
          color: glowingGreen,
          borderColor: glowingGreen,
          '&:hover': {
            borderColor: '#3a717a',
            backgroundColor: 'rgba(74, 143, 153, 0.04)', // Very light green background on hover
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#6abbc7', // Lighter shade of glowing green
        },
        barColorPrimary: {
          backgroundColor: glowingGreen,
        },
      },
    },
  },
});

export default theme;
