import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Container,
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import axios from 'axios';
import { RetellWebClient } from "retell-client-js-sdk";
import VerifyStep from './VerifyStep';

import WelcomeStep from './WelcomeStep';
import UserInfoStep from './UserInfoStep';
import AdditionalInfoStep from './AdditionalInfoStep';
import InterviewPrepStep from './InterviewPrepStep';
import { useAuth } from './AuthContext';
import config from './config';
import TipsStep from './TipsStep';
import Loader from './Components/Loader';

const steps = ['Welcome', 'Intro', 'User Info', 'Verify', 'Additional Info', 'Conversational Cover Letter'];

export default function NewUserWelcome() {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    countryCode: '',
    phoneNumber: '',
    email: '',
    error: '',
  });
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [, setResume] = useState(null);
  const [resumeFileName, setResumeFileName] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCallActive, setIsCallActive] = useState(false);
  const [callStatus, setCallStatus] = useState('');
  const [transcript, setTranscript] = useState([]);
  const retellWebClientRef = useRef(null);
  const [, setCallEnded] = useState(false);
  const [, setLoadingMessage] = useState('');
  const [, setCallId] = useState(null);

  const [viewportHeight, setViewportHeight] = useState('100vh');
  const [showAuthErrorDialog, setShowAuthErrorDialog] = useState(false);
  const [showCompletedApplicationDialog, setShowCompletedApplicationDialog] = useState(false);
  const [showOutOfCreditsDialog, setShowOutOfCreditsDialog] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { login } = useAuth();

  const [applicationForm, setApplicationForm] = useState(null);

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(`${window.innerHeight}px`);
    };

    updateViewportHeight();
    window.addEventListener('resize', updateViewportHeight);
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  useEffect(() => {
    if (token) {
      login(token);
    }
  }, [token, login]);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`${config.API_URL}/jobs/public/${jobId}`);
        if (response.status === 410) {
          const errorData = await response.json();
          setError(`This job posting is no longer available: ${errorData.detail}`);
        } else if (!response.ok) {
          throw new Error('No job found with this ID');
        } else {
          const data = await response.json();
          console.log('Job details received:', data);
          console.log('Application form:', data.application_form);
          setJobDetails(data);
          setApplicationForm(data.application_form);
        }
      } catch (err) {
        console.error('Error fetching job details:', err);
        setError('Failed to load job details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleNext = () => {
    if (activeStep === 0 && showJobDetails) {
      setShowJobDetails(false);
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleVerifyAndRegister = async (verificationCode) => {
    try {
      const response = await axios.post(`${config.API_URL}/users/auth_sms`, {
        email: userInfo.email,
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        phone_number: `+${userInfo.countryCode}${userInfo.phoneNumber}`,
        code: verificationCode
      });

      const { access_token } = response.data;
      setToken(access_token);

      await checkApplicationStatus(access_token);
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  };

  const checkApplicationStatus = async (token) => {
    try {
      const response = await axios.get(`${config.API_URL}/jobs/${jobId}/application-status`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.status === 'Completed') {
        setShowCompletedApplicationDialog(true);
      } else {
        handleNext();
      }
    } catch (error) {
      console.error('Error checking application status:', error);
      handleNext();
    }
  };

  const handleCloseCompletedApplicationDialog = () => {
    setShowCompletedApplicationDialog(false);
    setActiveStep(0); // Reset to the first step
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAdditionalInfoChange = (e) => {
    const { name, value } = e.target;
    setAdditionalInfo(prevState => {
      const newState = { ...prevState };
      if (name.startsWith('custom_')) {
        delete newState.customQuestions;
      }
      return {
        ...newState,
        [name]: value
      };
    });
  };

  const handleSubmitUserInfo = async () => {
    setIsSubmitting(true);
    setUserInfo(prevState => ({ ...prevState, error: '' }));

    if (!userInfo.phoneNumber) {
      setUserInfo(prevState => ({ ...prevState, error: 'Please enter a valid phone number.' }));
      setIsSubmitting(false);
      return;
    }

    try {
      const fullPhoneNumber = `+${userInfo.countryCode}${userInfo.phoneNumber}`;
      console.log(`Checking application status for phone: ${fullPhoneNumber}, job_id: ${jobId}`);

      const checkResponse = await axios.post(`${config.API_URL}/jobs/check-application-status`, {
        phone_number: fullPhoneNumber,
        job_external_id: jobId
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      console.log('Check response:', checkResponse.data);

      if (checkResponse.data.status === "completed") {
        setShowCompletedApplicationDialog(true);
        return;
      } else if (checkResponse.data.reason === "user_not_found") {
        console.log("No user found with this phone number. Proceeding with new application.");
      } else if (checkResponse.data.reason === "no_completed_application") {
        console.log("User found, but no completed application. Proceeding with new application.");
      }

      const smsResponse = await axios.post(`${config.API_URL}/users/send-sms-code`, {
        phone_number: fullPhoneNumber
      });

      if (smsResponse.status === 200) {
        handleNext();
      } else {
        throw new Error('Failed to send SMS code');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        if (error.response.status === 422) {
          setUserInfo(prevState => ({ ...prevState, error: 'Please check your phone number and try again.' }));
        } else if (error.response.status === 500) {
          setUserInfo(prevState => ({ ...prevState, error: 'Failed to send a one-time code to your phone number. Please double-check and try again.' }));
        } else {
          setUserInfo(prevState => ({ ...prevState, error: error.response.data.detail || 'An error occurred. Please try again.' }));
        }
      } else {
        setUserInfo(prevState => ({ ...prevState, error: 'Failed to process request. Please check your phone number and try again.' }));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitAdditionalInfo = async (additionalInfoData, resumeFile) => {
    try {
      setIsSubmitting(true);

      console.log('Submitting additional info:', additionalInfoData);
      console.log('Resume file:', resumeFile);

      delete additionalInfoData.customQuestions;

      // Set any undefined fields to empty strings
      Object.keys(additionalInfoData).forEach(key => {
        if (additionalInfoData[key] === undefined) {
          additionalInfoData[key] = "";
        }
      });

      const formData = new FormData();

      // Append the resume file if it exists
      if (resumeFile) {
        formData.append('resume', resumeFile);
      } else {
        console.warn('No resume file provided');
      }

      // Append all additionalInfoData as application_data
      formData.append('application_data', JSON.stringify(additionalInfoData));

      // Log formData contents for debugging
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await axios.post(`${config.API_URL}/jobs/${jobId}/apply`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Application submitted successfully:', response.data);
      handleNext();
    } catch (error) {
      console.error('Application submission failed:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        if (error.response.status === 401) {
          handleAuthError();
        } else if (error.response.status === 400 && error.response.data?.detail === "Organization is out of credits") {
          setShowOutOfCreditsDialog(true);
        } else {
          alert(`Application submission failed: ${error.response.data?.detail || error.message || 'An unknown error occurred'}`);
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        alert('No response received from the server. Please check your internet connection and try again.');
      } else {
        console.error('Error message:', error.message);
        alert(`Error: ${error.message}`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onResumeUpload = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setResume(file);
    setResumeFileName(file.name);
  }, []);

  const handleStartCall = async (selectedMicrophone) => {
    try {
      setError(null);

      if (!token) {
        throw new Error('No authentication token found. Please complete the registration process.');
      }

      const response = await fetch(`${config.API_URL}/jobs/create-call`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ jobId })
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Authentication error');
        }
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to create call');
      }

      const data = await response.json();
      setCallId(data.callId);

      console.log('Access token received:', data.accessToken);

      retellWebClientRef.current = new RetellWebClient();
      setupEventListeners();

      await retellWebClientRef.current.startCall({
        accessToken: data.accessToken,
        sampleRate: 24000,
        captureDeviceId: selectedMicrophone,
        emitRawAudioSamples: false,
      });

      // We're not setting isCallActive here anymore
      // The call_started event will handle this
      setCallStatus('Connecting...');
    } catch (err) {
      console.error('Start call error:', err);
      if (err.message === 'Authentication error') {
        handleAuthError();
      } else {
        setError(err.message);
      }
    }
  };

  const handleEndCall = async () => {
    if (retellWebClientRef.current) {
      retellWebClientRef.current.stopCall();
    }
    setIsCallActive(false);
    setCallStatus("Call ended");
    setLoading(true);
    setLoadingMessage('Processing your interview...');

    try {
      localStorage.clear();
      navigate('/application-complete');
    } catch (error) {
      console.error('Error processing transcript:', error);
      setError('Failed to process application. Please try again.');
    } finally {
      setLoading(false);
      setLoadingMessage('');
    }
  };

  const setupEventListeners = () => {
    if (!retellWebClientRef.current) return;

    retellWebClientRef.current.on("call_started", () => {
      console.log("Call started");
      setCallStatus("Call started");
      setIsCallActive(true);
      // Removed setLoading(false) from here
    });

    retellWebClientRef.current.on("call_ended", () => {
      console.log("Call ended");
      setCallStatus("Call ended");
      setIsCallActive(false);
      setCallEnded(true);
    });

    retellWebClientRef.current.on("agent_start_talking", () => {
      console.log("Agent started talking");
      setCallStatus("Agent is speaking");
    });

    retellWebClientRef.current.on("agent_stop_talking", () => {
      console.log("Agent stopped talking");
      setCallStatus("Agent finished speaking");
    });

    retellWebClientRef.current.on("update", (update) => {
      console.log("Update received:", update);
      if (update.event_type === 'update' && update.transcript) {
        setTranscript(update.transcript);
      }
    });

    retellWebClientRef.current.on("error", (error) => {
      console.error("An error occurred:", error);
      setError(`Call error: ${error.message}`);
      handleEndCall();
    });

    retellWebClientRef.current.on("disconnected", handleDisconnection);
  };

  const handleDisconnection = () => {
    setIsDisconnected(true);
  };

  const toggleJobDetails = () => {
    setShowJobDetails(prev => !prev);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleAuthError = () => {
    setShowAuthErrorDialog(true);
    setActiveStep(1);
    setToken(null);
    localStorage.removeItem('authToken');
  };

  const handleCloseAuthErrorDialog = () => {
    setShowAuthErrorDialog(false);
  };

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: viewportHeight }}>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <WelcomeStep
            onNext={handleNext}
            onToggleJobDetails={toggleJobDetails}
            showJobDetails={showJobDetails}
            jobDetails={jobDetails} // Add this line
          />
        );
      case 1:
        return (
          <TipsStep
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 2:
        return (
          <UserInfoStep
            userInfo={userInfo}
            onInputChange={handleInputChange}
            onSubmit={handleSubmitUserInfo}
            onBack={handleBack}
            isSubmitting={isSubmitting}
          />
        );
      case 3:
        return (
          <VerifyStep
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            onSubmit={handleVerifyAndRegister}
            userInfo={userInfo}
            onBack={handleBack}
          />
        );
      case 4:
        return (
          <AdditionalInfoStep
            additionalInfo={additionalInfo}
            onInputChange={handleAdditionalInfoChange}
            onSubmit={(additionalInfoData, resumeFile) => handleSubmitAdditionalInfo(additionalInfoData, resumeFile)}
            applicationForm={applicationForm}
            onResumeUpload={onResumeUpload}
            resumeFileName={resumeFileName}
          />
        );
      case 5:
        return (
          <InterviewPrepStep
            onStartInterview={handleStartCall}
            isCallActive={isCallActive}
            callStatus={callStatus}
            transcript={transcript}
            onEndCall={handleEndCall}
            onBack={handleBack}
            isDisconnected={isDisconnected}
            key="interview-prep-step"
          />
        );
      default:
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
            <Typography>Please refresh the page and try again.</Typography>
          </Box>
        );
    }
  };

  return (
    <Box
      sx={{
        minHeight: viewportHeight,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: (theme) => theme.palette.background.default,
        overflow: 'hidden',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          p: 2,
          bgcolor: (theme) => theme.palette.background.default,
          boxShadow: 0,
        }}
      >
        <Container maxWidth="xl">
          <img src="/logo.png" alt="Pluto Logo" style={{ maxWidth: '200px', height: 'auto' }} />
        </Container>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        <Container maxWidth="xl" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', py: 4 }}>
          <Stepper
            activeStep={activeStep}
            sx={{
              mb: 4,
              overflowX: 'auto',
              '& .MuiStepLabel-label': { typography: 'caption' },
              '& .MuiStep-root': { px: { xs: 0.5, sm: 1 } },
            }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12} md={showJobDetails && activeStep === 0 ? 7 : 12} sx={{ display: 'flex' }}>
              <Paper
                elevation={3}
                sx={{
                  p: { xs: 2, sm: 4 },
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  overflow: 'auto',
                }}
              >
                {getStepContent(activeStep)}
              </Paper>
            </Grid>
            {showJobDetails && activeStep === 0 && (
              <Grid item xs={12} md={5} sx={{ display: 'flex' }}>
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                  <Typography variant="h5" sx={{ mb: 3, color: '#4a8f99' }}>
                    Job Details
                  </Typography>
                  {jobDetails && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        {jobDetails.title}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        Company: {jobDetails.company_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          overflowWrap: 'break-word',
                          wordWrap: 'break-word'
                        }}
                      >
                        {jobDetails.description}
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={showAuthErrorDialog}
        onClose={handleCloseAuthErrorDialog}
        aria-labelledby="auth-error-dialog-title"
        aria-describedby="auth-error-dialog-description"
      >
        <DialogTitle id="auth-error-dialog-title">Authentication Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="auth-error-dialog-description">
            It's been a while and your token has expired. We need you to re-verify yourself!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAuthErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showCompletedApplicationDialog}
        onClose={handleCloseCompletedApplicationDialog}
        aria-labelledby="completed-application-dialog-title"
        aria-describedby="completed-application-dialog-description"
      >
        <DialogTitle id="completed-application-dialog-title">Application Already Completed</DialogTitle>
        <DialogContent>
          <DialogContentText id="completed-application-dialog-description">
            You've already completed your application for this job.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCompletedApplicationDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showOutOfCreditsDialog}
        onClose={() => setShowOutOfCreditsDialog(false)}
        aria-labelledby="out-of-credits-dialog-title"
        aria-describedby="out-of-credits-dialog-description"
      >
        <DialogTitle id="out-of-credits-dialog-title">Out of Credits</DialogTitle>
        <DialogContent>
          <DialogContentText id="out-of-credits-dialog-description">
            The organization is out of credits. Please try again later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOutOfCreditsDialog(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}