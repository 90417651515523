// import React, { useEffect } from 'react';
// import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';

// const ProtectedRoute = () => {
//   let globalErrorFlag = false;

//    const debounce = (func, wait) => {
//     let timeout;
//     return function (...args) {
//       const context = this;
//       clearTimeout(timeout);
//       timeout = setTimeout(() => func.apply(context, args), wait);
//     };
//     // return (...args) => {
//     //   clearTimeout(timeout);
//     //   timeout = setTimeout(() => func.apply(this, args), wait);
//     // };
//   };
  
//   const globalErrorNotifier = debounce((message) => {
//     // notify(message, "error");
//     globalErrorFlag = false;
//     logOut();
//   }, 1000);
  
//   const logOut = () => {
//     localStorage.clear();
//     window.location.href = "/login";
//     // notify("Please authenticate", "error");
//   };
  
//   const { isAuthenticated, logout } = useAuth();
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Extract query parameters from URL
//     const params = new URLSearchParams(location.search);
//     const organizationId = params.get('organization_id');

//     // Store organization_id in localStorage if it exists
//     if (organizationId) {
//       localStorage.setItem('organization_id', organizationId);
//     }

//     // Set up axios interceptor for token expiration
//     const interceptor = axios.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         if (error.response && error.response.status === 401) {
//           logout();
//           navigate('/signin', { state: { from: location } });
          
//         }
//         // if (!globalErrorFlag) {
//         //   globalErrorFlag = true;
//         //   globalErrorNotifier(error.response.statusText);
//         // }
//         return Promise.reject(error);
//       }
//     );

//     // Clean up the interceptor when the component unmounts
//     return () => {
//       axios.interceptors.response.eject(interceptor);
//     };
//   }, [location, logout, navigate]);

//   if (isAuthenticated === null) {
//     // Optionally render a loading spinner or similar here
//     return <div>Loading...</div>;
//   }

//   if (!isAuthenticated) {
//     // Redirect to sign-in with the original destination encoded as the redirect
//     return <Navigate to={`/signin?redirect=${location.pathname}`} />;
//   }

//   return <Outlet />;
// };

// export default ProtectedRoute;


// import React, { useEffect } from 'react';
// import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';

// const ProtectedRoute = () => {
//   const { isAuthenticated, logout } = useAuth();
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Extract query parameters from URL
//     const params = new URLSearchParams(location.search);
//     const organizationId = params.get('organization_id');

//     // Store organization_id in localStorage if it exists
//     if (organizationId) {
//       localStorage.setItem('organization_id', organizationId);
//     }

//     // Set up axios interceptor for token expiration
//     const interceptor = axios.interceptors.response.use(
//       (response) => response,
//       (error) => {
        
//         if (error.response && error.response.status === 401) {
//           console.log("ssssssssssss",error.response);
//           // Log the user out and navigate to sign-in page
//           logout();
//           navigate('/signin', { state: { from: location } });
//           window.location.href = "/signin";
//         }
//         return Promise.reject(error);
//       }
//     );

//     // Clean up the interceptor when the component unmounts
//     return () => {
//       axios.interceptors.response.eject(interceptor);
//     };
//   }, [location, logout, navigate]);

//   if (isAuthenticated === null) {
//     // Optionally render a loading spinner or similar here
//     return <div>Loading...</div>;
//   }

//   if (!isAuthenticated) {
//     // Redirect to sign-in with the original destination encoded as the redirect
//     return <Navigate to={`/signin?redirect=${location.pathname}`} />;
//   }

//   return <Outlet />;
// };

// export default ProtectedRoute;



// import React, { useEffect } from 'react';
// import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';

// const ProtectedRoute = () => {
//   const { isAuthenticated, logout } = useAuth();
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Extract query parameters from URL
//     const params = new URLSearchParams(location.search);
//     const organizationId = params.get('organization_id');

//     // Store organization_id in localStorage if it exists
//     if (organizationId) {
//       localStorage.setItem('organization_id', organizationId);
//     }

//     // Set up axios interceptor for token expiration (401 errors)
//     const interceptor = axios.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         console.log("Axios Interceptor Error: ", error.response);
//         if (error.response && error.response.status === 401) {
//           // Log the user out and navigate to sign-in page
//           logout();
//           // Debugging redirect issue
//           console.log('401 detected, redirecting to signin...');
//           navigate('/signin', { state: { from: location } });
//         }
//         return Promise.reject(error);
//       }
//     );

//     // Clean up the interceptor when the component unmounts
//     return () => {
//       axios.interceptors.response.eject(interceptor);
//     };
//   }, [location, logout, navigate]);

//   console.log('Is authenticated:', isAuthenticated);
//   console.log('Location:', location.pathname);

//   if (isAuthenticated === null) {
//     // Optionally render a loading spinner or similar while checking auth state
//     return <div>Loading...</div>;
//   }

//   if (!isAuthenticated) {
//     // Debugging redirect issue
//     console.log('Not authenticated, redirecting to signin...');
//     // Redirect to sign-in with the original destination encoded as the redirect
//     return <Navigate to={`/signin?redirect=${location.pathname}`} />;
//   }

//   // If the user is authenticated, render the child routes (Outlet)
//   return <Outlet />;
// };

// export default ProtectedRoute;



import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = () => {
  const navigate = useNavigate();

  // Log out and redirect to the sign-in page
  const logOutAndRedirect = () => {
    console.log("Logging out and redirecting to sign-in...");
    localStorage.clear(); // Clear any stored tokens or data
    window.location.href = "/signin"; // Redirect to sign-in
  };

  useEffect(() => {
    // Check if the token exists in localStorage
    const token = localStorage.getItem('token'); 
    if (!token) {
      console.log("No token found, redirecting to sign-in...");
      logOutAndRedirect(); // If no token, redirect to sign-in page
    }

    // Set up axios interceptor to handle 401 errors (Unauthorized)
    const interceptor = axios.interceptors.response.use(
      (response) => response, // If response is successful, just return it
      (error) => {
        // Log full error object for debugging
        console.log("Error in Axios response:", error);

        if (error.response) {
          console.log("Error response:", error.response);

          if (error.response.status === 401) {
            console.log("401 Unauthorized error detected, redirecting...");
            logOutAndRedirect();
          }
        } else if (error.request) {
          // Request was made but no response received
          console.log("No response received, request error:", error.request);
        } else {
          // Something happened in setting up the request
          console.log("Error setting up the request:", error.message);
        }

        return Promise.reject(error); // Forward the error if not 401
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  return <Outlet />; // Render the child components if authenticated
};

export default ProtectedRoute;
