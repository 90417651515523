import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  useTheme,
  Collapse,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CandidateDetailsComponent from './CandidateDetailsComponent';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "./Components/Loader";
import apiClient from './apiClient';

const COLUMN_ORDER = ["Pending", "Inbox", "Interview", "Offer", "Archive"];

const createInitialColumns = () => COLUMN_ORDER.reduce((acc, columnId) => {
  acc[columnId] = {
    id: columnId,
    title: columnId, // No need to capitalize the first letter as it's already capitalized
    items: []
  };
  return acc;
}, {});

const JobSelectionPage = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const [expandedJob, setExpandedJob] = useState(null);
  const [jobBoards, setJobBoards] = useState({});
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [candidateDialogOpen, setCandidateDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchJobs = useCallback(async () => {
    try {
      const response = await apiClient.get("/jobs/");
      setJobs(response.data);
    } catch (err) {
      setError(err.response?.data?.detail || "Failed to fetch job postings");
      setJobs([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleJobSelect = (job) => {
    navigate(`/job/${job.id}`);
  };

  const handleAddJob = () => {
    navigate("/add-job");
  };

  const handleToggleExpand = useCallback(async (jobId) => {
    if (expandedJob === jobId) {
      setExpandedJob(null);
    } else {
      setExpandedJob(jobId);
      if (!jobBoards[jobId]) {
        try {
          const response = await apiClient.get(`/jobs/${jobId}/candidates`);
          const candidates = response.data;

          const newColumns = createInitialColumns();
          candidates.forEach(candidate => {
            const column = newColumns[candidate.stage]; // Use the stage directly, it should match our column names
            if (column) {
              column.items.push({
                id: `candidate-${candidate.id}`,
                content: `${candidate.first_name} ${candidate.last_name}`,
                candidateId: candidate.id,
              });
            }
          });

          setJobBoards(prev => ({
            ...prev,
            [jobId]: newColumns
          }));
        } catch (error) {
          console.error("Failed to fetch candidates:", error);
          setError("Failed to load candidates. Please try again.");
        }
      }
    }
  }, [expandedJob, jobBoards]);

  const updateCandidateStage = useCallback(async (candidateId, newStage) => {
    try {
      await apiClient.patch(
        `/jobs/candidates/${candidateId}/stage`,
        { stage: newStage }
      );
    } catch (error) {
      console.error("Failed to update candidate stage:", error);
    }
  }, []);

  const handleCandidateClick = useCallback(async (candidateId, jobId) => {
    console.log("Clicked candidate:", candidateId, "for job:", jobId);
    try {
      const id = candidateId.split('-')[1]; // Extract the numeric ID
      console.log('Fetching details for candidate:', id);
      const response = await apiClient.get(`/jobs/candidates/${id}`);
      console.log('Fetched candidate details:', response.data);
      setSelectedCandidate({ ...response.data, jobId });
      setCandidateDialogOpen(true);
    } catch (err) {
      console.error('Error fetching candidate details:', err.response ? err.response.data : err.message);
      let errorMessage = 'Failed to load candidate details';
      if (err.response) {
        if (err.response.status === 404) {
          errorMessage = 'Candidate not found';
        } else if (err.response.data && err.response.data.detail) {
          errorMessage = err.response.data.detail;
        }
      }
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    }
  }, []);

  const handleCloseCandidateDialog = useCallback(() => {
    setCandidateDialogOpen(false);
    setSelectedCandidate(null);
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const onDragEnd = useCallback((result, jobId) => {
    const { source, destination } = result;

    if (!destination) return;

    setJobBoards(prev => {
      const newBoards = { ...prev };
      const board = { ...newBoards[jobId] };
      const sourceColumn = { ...board[source.droppableId] };
      const destColumn = { ...board[destination.droppableId] };
      const sourceItems = [...sourceColumn.items];
      const destItems = source.droppableId === destination.droppableId ? sourceItems : [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      if (source.droppableId === destination.droppableId) {
        board[source.droppableId] = { ...sourceColumn, items: destItems };
      } else {
        board[source.droppableId] = { ...sourceColumn, items: sourceItems };
        board[destination.droppableId] = { ...destColumn, items: destItems };
        // Call updateCandidateStage if the column has changed
        updateCandidateStage(removed.candidateId, destination.droppableId);
      }

      newBoards[jobId] = board;
      return newBoards;
    });
  }, [updateCandidateStage]);

  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper
        elevation={1}
        sx={{
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddJob}
            sx={{
              py: 1,
              bgcolor: theme.palette.primary.main,
              '&:hover': { bgcolor: theme.palette.primary.dark },
              borderRadius: theme.shape.borderRadius,
            }}
          >
            Add Job
          </Button>
        </Box>
        <List sx={{ flexGrow: 1, overflowY: 'auto', py: 1, px: 2 }}>
          {jobs.map((job) => (
            <React.Fragment key={job.id}>
              <ListItem
                sx={{
                  borderRadius: '4px',
                  my: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(74, 143, 153, 0.15)',
                  },
                }}
              >
                <ListItemText
                  primary={job.title}
                  onClick={() => handleJobSelect(job)}
                />
                <IconButton onClick={() => handleToggleExpand(job.id)}>
                  <ExpandMoreIcon />
                </IconButton>
              </ListItem>
              <Collapse in={expandedJob === job.id}>
                {jobBoards[job.id] && (
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, job.id)}>
                    <Box sx={{ display: 'flex', p: 2, gap: 2, overflowX: 'auto' }}>
                      {COLUMN_ORDER.map((columnId) => (
                        <Box
                          key={columnId}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '200px',
                            flexGrow: 1,
                          }}
                        >
                          <Typography variant="h6">{jobBoards[job.id][columnId].title}</Typography>
                          <Droppable droppableId={columnId} key={columnId}>
                            {(provided) => (
                              <Paper
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                sx={{
                                  bgcolor: 'background.default',
                                  p: 1,
                                  minHeight: 100,
                                  flexGrow: 1,
                                }}
                              >
                                {jobBoards[job.id][columnId].items.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <Paper
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{ p: 1, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                      >
                                        <Typography>{item.content}</Typography>
                                        <IconButton
                                          size="small"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleCandidateClick(item.id, job.id);
                                          }}
                                        >
                                          <AccountCircleIcon />
                                        </IconButton>
                                      </Paper>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </Paper>
                            )}
                          </Droppable>
                        </Box>
                      ))}
                    </Box>
                  </DragDropContext>
                )}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Paper>
      <Dialog
        open={candidateDialogOpen}
        onClose={handleCloseCandidateDialog}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <DialogTitle>
          Candidate Details
          <IconButton
            aria-label="close"
            onClick={handleCloseCandidateDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', p: 0, flexGrow: 1, overflow: 'hidden' }}>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 3 }}>
            {selectedCandidate && (
              <CandidateDetailsComponent
                candidate={selectedCandidate}
                jobId={selectedCandidate.jobId}
                onStageChange={(newStage) => {
                  // Implement stage change logic here
                  console.log("Stage changed to:", newStage);
                  // You might want to update the jobBoards state here
                }}
                onClose={handleCloseCandidateDialog}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JobSelectionPage;