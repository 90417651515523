import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    Container,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link as ScrollLink } from 'react-scroll';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.background,
    boxShadow: 'none',
}));

const LandingPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [, setCurrentWord] = useState(0);
    const words = useMemo(() => ['screen', 'vet', 'qualify', 'engage', 'source'], []);
    const [, setActiveStep] = useState(0);
    const stepsRef = useRef([]);
    const navigate = useNavigate();

    const [displayedWord, setDisplayedWord] = useState('');
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const typingSpeed = 250;
    const deletingSpeed = 200;
    const pauseBetweenWords = 1500;
    const [selectedRole, setSelectedRole] = useState('');

    useEffect(() => {
        if (isMobile) {
            // On mobile, rotate through words instantly
            const rotateWords = () => {
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                setDisplayedWord(words[currentWordIndex]);
            };

            const timer = setInterval(rotateWords, 3000); // Change word every 3 seconds

            return () => clearInterval(timer);
        } else {
            // On desktop, use the typing animation
            let timer;

            const typeWord = () => {
                const currentWord = words[currentWordIndex];

                if (!isDeleting && displayedWord !== currentWord) {
                    setDisplayedWord(currentWord.substring(0, displayedWord.length + 1));
                } else if (isDeleting && displayedWord !== '') {
                    setDisplayedWord(currentWord.substring(0, displayedWord.length - 1));
                } else if (isDeleting && displayedWord === '') {
                    setIsDeleting(false);
                    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                } else {
                    setIsDeleting(true);
                    timer = setTimeout(typeWord, pauseBetweenWords);
                    return;
                }

                timer = setTimeout(typeWord, isDeleting ? deletingSpeed : typingSpeed);
            };

            timer = setTimeout(typeWord, typingSpeed);

            return () => clearTimeout(timer);
        }
    }, [displayedWord, isDeleting, currentWordIndex, isMobile, words]); // Added 'words' to the dependency array

    const handleSignIn = () => {
        navigate('/signin'); // or '/login', depending on your route
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        const observers = [];
        stepsRef.current.forEach((step, index) => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setActiveStep(index);
                    }
                },
                { threshold: 0.5 }
            );
            observer.observe(step);
            observers.push(observer);
        });

        return () => {
            observers.forEach(observer => observer.disconnect());
        };
    }, []);

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 3000); // Change word every 3 seconds

        return () => clearInterval(wordInterval);
    }, [words.length]); // Added 'words.length' to the dependency array

    const navItems = [];

    const drawer = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: theme.palette.background.paper,
                py: 4, // Add some padding at the top and bottom
            }}
            onClick={handleDrawerToggle}
        >
            <List sx={{ width: '100%', maxWidth: 360 }}>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding>
                        <ScrollLink
                            to={item.target}
                            smooth={true}
                            duration={500}
                            offset={-64}
                            onClick={handleDrawerToggle}
                            style={{ width: '100%' }}
                        >
                            <ListItemText
                                primary={item.label}
                                primaryTypographyProps={{
                                    fontFamily: 'Manrope, sans-serif',
                                    fontWeight: 400,
                                    fontSize: '1.25rem',
                                    color: theme.palette.text.primary,
                                    textAlign: 'center',
                                }}
                            />
                        </ScrollLink>
                    </ListItem>
                ))}
                <ListItem disablePadding>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            handleSignIn();
                            handleDrawerToggle();
                        }}
                        sx={{
                            py: 1.5,
                            borderRadius: '20px',
                            mt: 2,
                            borderColor: theme.palette.primary.main,
                            '&:hover': {
                                borderColor: theme.palette.primary.dark,
                            },
                        }}
                    >
                        <ListItemText
                            primary="Sign In"
                            primaryTypographyProps={{
                                fontFamily: 'Manrope, sans-serif',
                                fontWeight: 400,
                                fontSize: '1.25rem',
                                color: theme.palette.text.primary,
                                textAlign: 'center',
                            }}
                        />
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        href="https://cal.com/team/hirepluto/product-demo"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleDrawerToggle}
                        sx={{ py: 1.5, borderRadius: '20px', mt: 2 }}
                    >
                        <ListItemText
                            primary="Get Started"
                            primaryTypographyProps={{
                                fontFamily: 'Manrope, sans-serif',
                                fontWeight: 400,
                                fontSize: '1.25rem',
                                color: theme.palette.primary.contrastText,
                                textAlign: 'center',
                            }}
                        />
                    </Button>
                </ListItem>
            </List>
        </Box>
    );

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    // Add a mapping of roles to their IDs
    const roleIds = {
        'airport-bus-driver': 1,
        'veterinarian': 2,
        'machine-operator': 3,
        'maintenance-technician': 4
    };

    // Modify the button click handler
    const handleNextClick = () => {
        if (selectedRole) {
            const roleId = roleIds[selectedRole];
            navigate(`/experience/${roleId}`);
        }
    };

    return (
        <Box sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <StyledAppBar position="static">
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        {/* Removing the logo container */}
                    </Toolbar>
                </Container>
            </StyledAppBar>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <SwipeableDrawer
                    anchor="top"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: {
                            maxHeight: '80%', // Limit the height to 80% of the viewport
                            overflowY: 'auto', // Add scrolling if content exceeds the height
                        },
                    }}
                >
                    {drawer}
                </SwipeableDrawer>

                <Container
                    maxWidth="lg"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        px: 3
                    }}
                >
                    <Box
                        component="img"
                        src="/logo.png"
                        alt="Pluto Logo"
                        sx={{
                            height: { xs: '40px', sm: '50px', md: '60px' },
                            mb: 2,
                        }}
                    />
                    <Typography
                        variant="h2"
                        component="h2"
                        align="center"
                        sx={{
                            fontWeight: 'normal',
                            color: theme.palette.text.primary,
                            mt: 0,
                            mb: 2,
                            fontSize: { xs: '2.5rem', sm: '3rem', md: '3.75rem' },
                        }}
                    >
                        <Box component="span" display={{ xs: 'block', sm: 'inline' }}>Your recruiter</Box>{' '}
                        <Box component="span" display={{ xs: 'block', sm: 'inline' }}>
                            copilot to <span style={{ color: theme.palette.glowingGreen.main }}>{displayedWord}</span>.
                        </Box>
                    </Typography>

                    <Box
                        sx={{
                            width: '100%',
                            padding: 4,
                            backgroundColor: alpha(theme.palette.background.paper, 0.6),
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '8px',
                            gap: 3,
                            mb: 4,
                            mt: 6,
                        }}
                    >
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Choose role
                        </Typography>
                        <Select
                            value={selectedRole}
                            onChange={handleRoleChange}
                            sx={{
                                minWidth: 300,
                                borderRadius: '8px',
                            }}
                        >
                            <MenuItem value="airport-bus-driver">Airport Bus Driver</MenuItem>
                            <MenuItem value="veterinarian">Veterinarian</MenuItem>
                            <MenuItem value="machine-operator">Machine Operator</MenuItem>
                            <MenuItem value="maintenance-technician">Maintenance Technician</MenuItem>
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleNextClick}  // Add onClick handler
                            disabled={!selectedRole}   // Disable button if no role selected
                            sx={{
                                mt: 2,
                                fontWeight: 400,
                                fontSize: '1.1rem',
                                padding: '12px 24px',
                                borderRadius: '20px',
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default LandingPage;
