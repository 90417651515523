// apiClient.js
import axios from 'axios';
import config from './config';  // Assuming this contains your API URL and other config

const apiClient = axios.create({
  baseURL: config.API_URL, // Your API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Attach token in every request automatically
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get token from localStorage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Set Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // Handle request errors
  }
);

// Setup an interceptor for token expiration
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.dispatchEvent(new CustomEvent('unauthorized'));
    }
    return Promise.reject(error);
  }
);

export default apiClient;
