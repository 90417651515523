import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedRole = localStorage.getItem('userRole');
    console.log('Initial token from localStorage:', storedToken);
    console.log('Initial role from localStorage:', storedRole);
    if (storedToken) {
      setIsAuthenticated(true);
      setToken(storedToken);
      setUserRole(storedRole);
    }
  }, []);

  const login = (newToken, role) => {
    console.log('Logging in with token:', newToken);
    console.log('User role:', role);
    localStorage.setItem('token', newToken);
    localStorage.setItem('userRole', role);
    setToken(newToken);
    setUserRole(role);
    setIsAuthenticated(true);
  };

  const logout = () => {
    console.log('Logging out, clearing token and role');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    setToken(null);
    setUserRole(null);
    setIsAuthenticated(false);
    window.location.href = "/signin";
  };

  const getToken = () => {
    const currentToken = localStorage.getItem('token');
    console.log('Getting token:', currentToken);
    return currentToken;
  };

  return (
   <>
       {/* <CircularProgress /> */}
     <AuthContext.Provider value={{ isAuthenticated, login, logout, getToken, token, userRole }}>
      {children}
    </AuthContext.Provider>
   </>
  );
};

export const useAuth = () => useContext(AuthContext);