import * as Sentry from "@sentry/react";
import process from 'process';

export const initSentry = () => {
  Sentry.init({
    dsn: "https://93a94d9a943a8ef94388b7296233c559@o4507971504898048.ingest.us.sentry.io/4507971514925056",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/hirepluto\.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enabled: process.env.NODE_ENV !== 'development',
  });
};
