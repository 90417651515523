import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';

const VerifyStep = ({ verificationCode, setVerificationCode, onSubmit, userInfo, onBack }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const themeColor = '#4a8f99';

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            await onSubmit(verificationCode);
            // If onSubmit succeeds, it will handle the navigation
        } catch (err) {
            if (err.response && err.response.status === 409) {
                setError("Phone number already registered");
            } else {
                setError(err.response?.data?.detail || "An error occurred. Please try again.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseError = () => {
        setError(null);
    };

    return (
        <Container maxWidth="md" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            overflowY: 'auto'
        }}>
            <Box sx={{
                width: '100%',
                border: `2px solid ${themeColor}`,
                borderRadius: '8px',
                padding: '24px'
            }}>
                <Typography variant="h5" align="center" sx={{ mb: 4, color: themeColor }}>
                    Verify Your Phone Number
                </Typography>
                <Typography variant="body1" align="center" sx={{ mb: 2 }}>
                    We've sent a verification code to +{userInfo.countryCode}{userInfo.phoneNumber}
                </Typography>
                <TextField
                    fullWidth
                    label="Verification Code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    sx={{ mb: 3 }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button
                        onClick={onBack}
                        variant="outlined"
                        disabled={isLoading}
                        sx={{
                            color: themeColor,
                            borderColor: themeColor,
                            '&:hover': { borderColor: '#3b7b84' }
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={isLoading}
                        sx={{
                            bgcolor: themeColor,
                            '&:hover': { bgcolor: '#3b7b84' },
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        Verify & Continue
                        {isLoading && (
                            <CircularProgress
                                size={20}
                                color="inherit"
                                sx={{ ml: 1 }}
                            />
                        )}
                    </Button>
                </Box>
            </Box>
            <Dialog
                open={!!error}
                onClose={handleCloseError}
                aria-labelledby="error-dialog-title"
                aria-describedby="error-dialog-description"
            >
                <DialogTitle id="error-dialog-title">{"Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="error-dialog-description">
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseError} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default VerifyStep;