import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ExperienceVideoPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showPreview, setShowPreview] = useState(false);

    const videoSources = {
        '1': 'https://www.loom.com/embed/bfaf9479c0584e2c9ef2dfd6c883bcac?sid=9488e0f1-7060-4059-9829-eba010ecd275',
        '2': 'https://www.loom.com/embed/c208ef87ecf14ae280534d54e02fa1e6?sid=e724b882-36be-41de-82cf-84cd46867799',
        '3': 'https://www.loom.com/embed/add17a345f034cb9ac82a5b1bf83b7c3?sid=88cb3686-fb6a-4d8f-810d-879d3431f0c8',
        '4': 'https://www.loom.com/embed/e9c21950e447485bbf709684584f4f54?sid=00b642bf-90a0-4656-8766-0cf17f050d01'
    };

    const previewImages = {
        '1': '/AirportBusDriver.jpeg',
        '2': '/veterinarian.jpeg',
        '3': '/machine_operator.jpeg',
        '4': '/maintenance_technician.jpeg'
    };

    const videoSrc = videoSources[id] || videoSources['1'];
    const previewSrc = previewImages[id] || previewImages['1'];

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '800px',
            margin: '0 auto',
            padding: '20px',
            position: 'relative'
        }}>
            {showPreview && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    zIndex: 1000,
                    padding: '20px',
                    overflow: 'auto',
                    maxHeight: '100vh'
                }} onClick={() => setShowPreview(false)}>
                    <div style={{
                        width: '95vw',
                        position: 'relative',
                        margin: '0 auto'
                    }}>
                        <img
                            src={previewSrc}
                            alt="HirePluto Analysis Example"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                                borderRadius: '8px',
                                display: 'block'
                            }}
                        />
                    </div>
                </div>
            )}

            <div style={{ width: '100%' }}>
                <img
                    src="/logo.png"
                    alt="HirePluto Logo"
                    style={{
                        display: 'block',
                        maxWidth: '200px',
                        margin: '0 auto 30px auto'
                    }}
                />
                <div style={{ position: 'relative', paddingBottom: '67.66917293233082%', height: 0 }}>
                    <iframe
                        src={videoSrc}
                        frameBorder="0"
                        title="HirePluto Analysis Example"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen={true}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%'
                        }}
                    />
                </div>
                <button
                    onClick={() => setShowPreview(true)}
                    style={{
                        width: '100%',
                        padding: '12px',
                        marginTop: '20px',
                        backgroundColor: '#017074',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s'
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#015758'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#017074'}
                >
                    View Analysis Example
                </button>

                <button
                    onClick={() => navigate('/experience')}
                    style={{
                        width: '100%',
                        padding: '12px',
                        marginTop: '10px',
                        backgroundColor: 'transparent',
                        color: '#017074',
                        border: '2px solid #017074',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        transition: 'all 0.2s'
                    }}
                    onMouseOver={(e) => {
                        e.target.style.backgroundColor = '#017074';
                        e.target.style.color = 'white';
                    }}
                    onMouseOut={(e) => {
                        e.target.style.backgroundColor = 'transparent';
                        e.target.style.color = '#017074';
                    }}
                >
                    Back
                </button>
            </div>
        </div>
    );
};

export default ExperienceVideoPage;

