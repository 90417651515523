import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import apiClient from './apiClient';
import config from './config';

const CandidateDashboard = () => {
  const [applications, setApplications] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/signin');
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await apiClient.get('/jobs/applications/me');
        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    fetchApplications();
  }, []);

  return (
    <Box sx={{ p: { xs: 2, md: 3 }, height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', md: 'center' },
        gap: { xs: 2, md: 0 },
        width: '100%',
        mb: 4
      }}>
        <img
          src="/logo.png"
          alt="HirePluto Logo"
          style={{
            width: '150px',
            height: 'auto',
            objectFit: 'contain'
          }}
        />

        <Typography variant="h4" sx={{ 
          fontSize: { xs: '1.5rem', md: '2.125rem' },
          width: { xs: '100%', md: 'auto' },
          textAlign: { xs: 'center', md: 'left' }
        }}>
          Candidate Dashboard
        </Typography>

        <Button
          variant="outlined"
          color="error"
          onClick={handleOpenDialog}
          sx={{
            width: { xs: '100%', md: 'auto' },
            height: 'fit-content',
            backgroundColor: '#ffebee',
            borderColor: '#d32f2f',
            color: '#d32f2f',
            '&:hover': {
              backgroundColor: '#ffcdd2',
              borderColor: '#c62828'
            }
          }}
        >
          Request Data Deletion
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Data Deletion Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To request the deletion of your data from our servers, please email support@hirepluto.com from your registered account email with the subject line 'Data Deletion Request.'
            <br /><br />
            In the email, clearly specify that you wish to have all your personal data permanently removed from our systems. Once your request is processed, we will send a confirmation.
            <br /><br />
            Please note that this action is irreversible, and any companies currently reviewing your applications will no longer have access to your profile.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            href="mailto:support@hirepluto.com?subject=Data%20Deletion%20Request"
          >
            Send Email
          </Button>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {applications.length === 0 ? (
        <Paper sx={{ p: { xs: 2, md: 4 }, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            No applications found. Apply to jobs to see your applications here.
          </Typography>
        </Paper>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            mb: 4,
            maxWidth: '100%',
            overflowX: 'auto'
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Job Title</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Date Applied</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((app) => (
                <TableRow key={app.id}>
                  <TableCell>{app.job_title}</TableCell>
                  <TableCell>{app.company_name}</TableCell>
                  <TableCell>{new Date(app.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>{app.status}</TableCell>
                  <TableCell>
                    {(app.status === 'Pending' || app.status === 'In Progress') && (
                      <Button
                        variant="contained"
                        color="primary"
                        href={`${config.WEBSITE_URL}/apply/new_user/${app.job_external_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Finish Application
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleLogout}
        sx={{ width: { xs: '100%', md: 'auto' } }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default CandidateDashboard;