import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField,
  Snackbar,
  useTheme,
  Grid,
} from '@mui/material';
import {
  Person as PersonIcon,
} from '@mui/icons-material';
import Loader from './Components/Loader';
import apiClient from './apiClient';

const AccountsPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('personal');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await apiClient.get('/users/me');
      setUser(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiClient.put('/users/me', user);
      showSnackbar('User information updated successfully');
    } catch (err) {
      showSnackbar('Error updating user information');
      console.error('Error updating user data:', err);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };



  if (loading) {
    return (
      <Loader />
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  const renderContent = () => {
    switch (activeSection) {
      case 'personal':
        return (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={user.first_name || ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={user.last_name || ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={user.email || ''}
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
            />
            {/* <TextField
              fullWidth
              label="Phone Number"
              name="phone_number"
              value={user.phone_number || ''}
              onChange={handleInputChange}
              margin="normal"
            /> */}
            {/* <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save Changes
            </Button> */}
          </form>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Paper elevation={1} sx={{ height: "100%" }}>
            <List>
              {[
                { id: 'personal', label: 'Personal Information', icon: <PersonIcon /> },
              ].map((item) => {
                return (
                  <ListItem
                    button
                    key={item.id}
                    selected={activeSection === item.id}
                    onClick={() => setActiveSection(item.id)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
              })}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={9}>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
            <Paper
              elevation={1}
              sx={{
                p: 3,
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Typography variant="h4" sx={{ mb: 3, color: theme.palette.primary.main }}>
                {activeSection === 'personal' && 'Personal Information'}
              </Typography>
              <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {renderContent()}
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default AccountsPage;