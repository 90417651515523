import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Avatar, Button } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';

const TranscriptDisplay = ({ transcript, audioSrc }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateTime = () => setCurrentTime(audio.currentTime);
    audio.addEventListener('timeupdate', updateTime);
    return () => audio.removeEventListener('timeupdate', updateTime);
  }, []);

  const highlightWord = (word, start, end) => {
    if (currentTime >= start && currentTime <= end) {
      return { backgroundColor: 'yellow', padding: '0 2px' };
    }
    return {};
  };

  // Parse the transcript if it's a string
  const parsedTranscript = typeof transcript === 'string' ? JSON.parse(transcript) : transcript;

  return (
    <Box sx={{ bgcolor: '#f0f0f0', p: 2, borderRadius: 2 }}>
      <audio ref={audioRef} src={audioSrc} />
      <Button sx={{ mb: 2 }}></Button>
      {parsedTranscript.map((entry, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="flex-start"
          mb={2}
          flexDirection={entry.role === 'user' ? 'row-reverse' : 'row'}
        >
          <Avatar
            sx={{
              bgcolor: entry.role === 'user' ? '#007AFF' : '#A9A9A9',
              mr: entry.role === 'user' ? 0 : 1,
              ml: entry.role === 'user' ? 1 : 0
            }}
          >
            {entry.role === 'user' ? <PersonIcon /> : <><img src='/logo192.png' width={"40px"} alt="AI Assistant" /></>}
          </Avatar>
          <Box
            sx={{
              bgcolor: entry.role === 'user' ? '#007AFF' : '#E5E5EA',
              p: 2,
              borderRadius: 3,
              maxWidth: '70%',
              boxShadow: 1
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: entry.role === 'user' ? 'white' : 'black'
              }}
            >
              {entry.words ? (
                entry.words.map((wordInfo, wordIndex) => (
                  <span key={wordIndex} style={highlightWord(wordInfo.word, wordInfo.start, wordInfo.end)}>
                    {wordInfo.word}{' '}
                  </span>
                ))
              ) : (
                entry.content
              )}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TranscriptDisplay;